const setWithExp = (key, value, exp) => {
  const date = new Date();
  const item = { value: value, expiration: date.getTime() + exp * 1000 };
  localStorage.setItem(key, JSON.stringify(item));
};

const getWithExp = (key) => {
  const itemStr = localStorage.getItem(key);
  if (!itemStr) {
    return null;
  }
  const item = JSON.parse(itemStr);
  const date = new Date();
  if (date.getTime() > item.expiration) {
    localStorage.removeItem(key);
    return null;
  }
  return item.value;
};

const clearStorage = () => {
  localStorage.removeItem("JWT");
  localStorage.removeItem("userUUID");
  localStorage.removeItem("userEmail");
  localStorage.removeItem("userHasActiveSubscription");
  localStorage.removeItem("requestPayment");
};

const localStorageSRV = {
  setWithExp,
  getWithExp,
  clearStorage,
};

export default localStorageSRV;
