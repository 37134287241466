import httpHeadersSRV from "../services/HttpHeadersSRV";
import localStorageSRV from "../services/LocalStorageSRV";

const create = () => {
  const requestOptions = {
    method: "POST",
    headers: httpHeadersSRV.postHeadersWithAuth(),
    body: JSON.stringify({}),
  };

  return fetch(
    `${process.env.REACT_APP_API_URL}/api/v1/stripe/billing_sessions`,
    requestOptions
  ).then(
    (requestSuccess) => {
      if (requestSuccess.ok) {
        return requestSuccess.json().then((parsedData) => {
          return parsedData;
        });
      }
      if (requestSuccess.status === 401) {
        localStorageSRV.clearStorage();
      }
      return requestSuccess.json().then((parsedData) => {
        return Promise.reject(parsedData);
      });
    },
    () => {
      return Promise.reject({ error: "Service unavailable" });
    }
  );
};

const billingSessionSRV = {
  create,
};

export default billingSessionSRV;
