import httpHeadersSRV from "../services/HttpHeadersSRV";
import localStorageSRV from "../services/LocalStorageSRV";

const questionIntervals = questionUUID => {
  const requestOptions = {
    method: 'GET',
    headers: httpHeadersSRV.getHeadersWithAuth()
  };

  return fetch(`${process.env.REACT_APP_API_URL}/api/v1/questions/${questionUUID}/sm_variables/intervals`, requestOptions).then(
    requestSuccess => {
      if(requestSuccess.ok) {
        return requestSuccess.json().then(parsedData => { return parsedData });
      }
      if(requestSuccess.status === 401) {
        localStorageSRV.clearStorage();
      }
      return requestSuccess.json().then(parsedData => { return Promise.reject(parsedData) });
    },
    () => {
      return Promise.reject({ error: 'Service unavailable' });
    });
}

const gradeSRV = {
  questionIntervals
};

export default gradeSRV;