import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Col,
  OverlayTrigger,
  Popover,
  Row,
  Spinner,
  Tab,
  Tabs,
} from "react-bootstrap";

import "./subscription.css";
import productsSRV from "../ProductsSRV";
import checkoutSRV from "../CheckoutSRV";
import { useNavigate } from "react-router-dom";
import localStorageSRV from "../../services/LocalStorageSRV";
import billingSessionSRV from "../../account/BillingSessionSRV";

export default function Subscription() {
  const navigate = useNavigate();
  const [product, setProduct] = useState({ prices: [] });
  const [isLoading, setIsLoading] = useState(true);
  const requestPayment = localStorageSRV.getWithExp("requestPayment");

  useEffect(() => {
    productsSRV
      .index()
      .then(
        (response) => {
          setProduct(response[0]);
        },
        (error) => {
          console.log(error);
          navigate("/", { replace: true });
        }
      )
      .finally(() => {
        setIsLoading(false);
      });
  }, [navigate]);

  function Checkout(priceId) {
    if (localStorageSRV.getWithExp("JWT") === null) {
      return navigate("/sign-in", { replace: true });
    }
    checkoutSRV.create(priceId).then(
      (response) => {
        window.location.href = response.url;
      },
      (error) => {}
    );
  }

  function RenderSubscription() {
    return (
      <div className="my-5">
        <Row className="mb-1">
          <Col>
            <Card border="primary">
              <Card.Header
                className="bg-primary bg-gradient text-white text-center"
                as="h5"
              >
                Selectează unul dintre abonamentele noastre
              </Card.Header>
              <Card.Body>
                <Card.Text>
                  Fiecare plan include <b>o săptămână gratuită</b> pentru a te
                  familiariza cu platforma și modul de învățare. Nu este
                  necesară introducerea datelor bancare în perioada de probă.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        {isLoading ? loadingSpinner() : RenderTabsComponent()}
      </div>
    );
  }

  function RenderTabsComponent() {
    return (
      <Tabs
        id="subscription"
        className="bg-secondary tabs-bg-opacity"
        defaultActiveKey={product.default_price_id}
        variant="pills"
        justify
      >
        {RenderTabComponents()}
      </Tabs>
    );
  }

  function RenderTabComponents() {
    return product.prices.map((price, index) => (
      <Tab key={index} eventKey={price.id} title={price.interval}>
        <Row
          xs={1}
          md={2}
          className="mt-2 justify-content-center align-items-center"
        >
          <Col>
            <Card bg="secondary" className="tabs-bg-opacity">
              <Card.Img
                variant="top"
                src={product.image}
                alt="Grile Rezidentiat"
                className="product-img"
              />
              <Card.Body>
                <Card.Title>{product.name}</Card.Title>
                <Card.Text>{product.description}</Card.Text>
                <Card.Text>{price.nickname}</Card.Text>
                <Card.Text className="fs-1 fw-bold">
                  {price.amount} / {price.interval}
                </Card.Text>
                {RenderSubscriptionButton(price.id)}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Tab>
    ));
  }

  function RenderSubscriptionButton(priceId) {
    const hasActiveSubscription = localStorageSRV.getWithExp(
      "userHasActiveSubscription"
    );

    const popover = (
      <Popover id="popover-subscription">
        <Popover.Header as="h3">Ai un abonament activ!</Popover.Header>
        <Popover.Body>
          Iți poți modifica abonamentul folosind link-ul{" "}
          <b>Gestionează-ți abonamentul</b>
        </Popover.Body>
      </Popover>
    );

    const activeButton = (
      <Button size="lg" variant="success" onClick={() => Checkout(priceId)}>
        Încearcă Gratuit
      </Button>
    );

    const disabledButton = (
      <OverlayTrigger overlay={popover} placement="bottom">
        <span>
          <Button size="lg" variant="success" disabled>
            Încearcă Gratuit
          </Button>
        </span>
      </OverlayTrigger>
    );

    return (
      <div className="d-flex justify-content-center align-items-center">
        {hasActiveSubscription ? disabledButton : activeButton}
      </div>
    );
  }

  const loadingSpinner = () => {
    return (
      <div className="d-flex justify-content-center">
        <Spinner className="d-flex" animation="border" variant="primary" />
      </div>
    );
  };

  const requestPaymentComponent = () => (
    <div className="my-5">
      <Row className="mb-1">
        <Col>
          <Card border="primary">
            <Card.Header
              className="bg-primary bg-gradient text-white text-center"
              as="h5"
            >
              Abonament
            </Card.Header>
            <Card.Body>
              <Card.Text>
                Ne pare rău să vă informăm că plata pentru abonamentul
                dumneavoastră nu a putut fi procesată. Pentru a remedia această
                situație, vă rugăm să verificați informațiile de plată asociate
                contului dumneavoastră.
              </Card.Text>
              <Card.Text>
                Vă recomandăm să verificați cu atenție numele de pe card,
                numărul cardului, data de expirare și codul de securitate,
                pentru a vă asigura că informațiile sunt corecte și actualizate.
              </Card.Text>
            </Card.Body>
            <Card.Footer>
              <Button
                size="lg"
                variant="primary"
                onClick={() => createBillingSession()}
              >
                Gestionează-ți abonamentul
              </Button>
            </Card.Footer>
          </Card>
        </Col>
      </Row>
    </div>
  );

  const createBillingSession = () => {
    billingSessionSRV.create().then(
      (response) => {
        window.location.href = response.url;
      },
      (error) => {}
    );
  };

  return requestPayment ? requestPaymentComponent() : RenderSubscription();
}
