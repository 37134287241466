import React from "react";
import { Button } from "react-bootstrap";

export default function FooterButtons() {
  return (
    <div className="d-flex justify-content-center align-items-center">
      <Button size="lg" href="/subscriptions" variant="primary">
        Încearcă Gratuit
      </Button>
    </div>
  );
}
