import httpHeadersSRV from "./HttpHeadersSRV";
import localStorageSRV from "./LocalStorageSRV";

const resetPassword = (uuid, user, token) => {
  const requestOptions = {
    method: 'PATCH',
    headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': `Bearer ${token}`},
    body: JSON.stringify({user: user})
  };

  return fetch(`${process.env.REACT_APP_API_URL}/api/v1/users/${uuid}/reset_password`, requestOptions).then(
    requestSuccess => {
      if(requestSuccess.ok) {
        return requestSuccess.json().then(parsedData => { return parsedData });
      }
      if(requestSuccess.status === 401) {
        localStorageSRV.clearStorage();
      }
      return requestSuccess.json().then(parsedData => { return Promise.reject(parsedData) });
    },
    () => {
      return Promise.reject({ error: 'Service unavailable' });
    });
}

const logout = () => {
  const requestOptions = {
    method: 'DELETE',
    headers: httpHeadersSRV.getHeadersWithAuth()
  };

  return fetch(`${process.env.REACT_APP_API_URL}/api/v1/session`, requestOptions).then(
    requestSuccess => {
      if(requestSuccess.ok) {
        return requestSuccess.json().then(parsedData => { return parsedData });
      }
      if(requestSuccess.status === 401) {
        localStorageSRV.clearStorage();
      }
      return requestSuccess.json().then(parsedData => { return Promise.reject(parsedData) });
    },
    () => {
      return Promise.reject({ error: 'Service unavailable' });
    });
}

const userSRV = {
  resetPassword,
  logout
}

export default userSRV;