import React from "react";
import NavigationBar from "../navigationBar/NavigationBar";
import {
  Button,
  Card,
  Col,
  Container,
  FloatingLabel,
  Form,
  Row,
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { Formik } from "formik";
import forgotPasswordSRV from "./forgotPasswordSRV";
import FooterBar from "../footerBar/FooterBar";

import "./forgotPassword.css";
import logo from "../public/images/logo_full_size.png";

export default function ForgotPassword() {
  const navigate = useNavigate();

  const schema = Yup.object().shape({
    email: Yup.string().email().required(),
  });

  return (
    <div className="min-vh-100 d-flex flex-column">
      <NavigationBar />
      <Container>
        <Row
          lg={2}
          className="min-vh-100 align-items-center justify-content-center"
        >
          <Col>
            <Card border="primary">
              <Card.Header
                className="bg-primary bg-gradient text-white text-center"
                as="h3"
              >
                Ai uitat parola?
              </Card.Header>
              <Card.Img variant="top" src={logo} className="logo-img" />
              <Card.Body>
                <Card.Text>
                  Vă rugăm, introduceți adresa de email mai jos. Veți primi un
                  link pentru resetarea parolei.
                </Card.Text>
                <Formik
                  initialValues={{ email: "" }}
                  validationSchema={schema}
                  onSubmit={(values, { setSubmitting }) => {
                    forgotPasswordSRV.resetPassword(values).then(
                      (response) => {
                        navigate("/check_inbox");
                      },
                      (error) => {
                        setSubmitting(false);
                      }
                    );
                  }}
                >
                  {({
                    handleSubmit,
                    handleChange,
                    handleBlur,
                    values,
                    touched,
                    isValid,
                    errors,
                    isSubmitting,
                  }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                      <FloatingLabel
                        controlId="user-email"
                        label="Email"
                        className="mb-3"
                      >
                        <Form.Control
                          type="email"
                          name="email"
                          placeholder="Email"
                          value={values.email}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={!!errors.email}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.email}
                        </Form.Control.Feedback>
                      </FloatingLabel>
                      <Button
                        variant="primary"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        Trimite
                      </Button>
                    </Form>
                  )}
                </Formik>
              </Card.Body>
              <Card.Footer>
                <Card.Text className="text-center">
                  Dacă ai deja cont, <Link to="/sign-in">Autentifică-te</Link>
                </Card.Text>
              </Card.Footer>
            </Card>
          </Col>
        </Row>
      </Container>
      <FooterBar />
    </div>
  );
}
