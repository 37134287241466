import localStorageSRV from "./LocalStorageSRV";

const getHeaders = () => ({ 'Accept': 'application/json' })
const postHeaders = () => ({ 'Content-Type': 'application/json', 'Accept': 'application/json' })

const getHeadersWithAuth = () => {
  const jwt = localStorageSRV.getWithExp("JWT");
  return { 'Accept': 'application/json', 'Authorization': `Bearer ${jwt}`};
}

const postHeadersWithAuth = () => {
  const jwt = localStorageSRV.getWithExp("JWT");
  return { 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': `Bearer ${jwt}`};
}

const httpHeadersSRV = {
  getHeaders,
  postHeaders,
  getHeadersWithAuth,
  postHeadersWithAuth
};

export default httpHeadersSRV;
