import React from "react";
import { Card } from "react-bootstrap";
import FolderTree from "react-folder-tree";
export default function QuestionHierarchy({ questionHierarchy }) {
  return (
    <Card border="primary">
      <Card.Header
        as="h4"
        className="bg-primary bg-gradient text-center text-white"
      >
        Capitol
      </Card.Header>
      <Card.Body>
        <FolderTree data={questionHierarchy} showCheckbox={false} readOnly />
      </Card.Body>
    </Card>
  );
}
