import React from "react";
import { Container } from "react-bootstrap";
import NavigationBar from "../navigationBar/NavigationBar";
import Subscription from "./components/subscription";
import FooterBar from "../footerBar/FooterBar";

export default function SubscriptionsPage() {
  return (
    <div className="min-vh-100 d-flex flex-column">
      <NavigationBar />
      <Container>{Subscription()}</Container>
      <FooterBar />
    </div>
  );
}
