import React, { useEffect, useState } from "react";
import { DropdownButton, Dropdown, ButtonGroup, Button } from "react-bootstrap";
import questionSRV from "../questions/QuestionSRV";
import gradeSRV from "./gradeSRV";
import UseWindowDimensions from "../services/useWindowDimensions";

export default function Grade(props) {
  const { width } = UseWindowDimensions();
  const [intervals, setIntervals] = useState({});

  useEffect(() => {
    gradeSRV.questionIntervals(props.questionUUID).then(
      (response) => {
        setIntervals({
          wrong: response.wrong,
          correct: response.correct,
          easy: response.easy,
        });
      },
      (error) => {
        console.log(error);
      }
    );
  }, [props.questionUUID]);

  function GradeQuestionComponent() {
    const xs = 576;
    if (width <= xs) return DropDownButtonComponent();

    return ButtonGroupComponent();
  }

  function ButtonGroupComponent() {
    return (
      <ButtonGroup className="mt-3">
        <Button
          variant="danger"
          className="bg-gradient"
          onClick={() => HandleSubmit("wrong")}
        >
          Greșit (<b>30 minute</b>)
        </Button>
        <Button
          variant="success"
          className="bg-gradient"
          onClick={() => HandleSubmit("correct")}
        >
          Corect ({DisplayInterval(intervals.correct)})
        </Button>
        <Button
          variant="primary"
          className="bg-gradient"
          onClick={() => HandleSubmit("easy")}
        >
          Ușor ({DisplayInterval(intervals.easy)})
        </Button>
      </ButtonGroup>
    );
  }

  function DropDownButtonComponent() {
    return (
      <DropdownButton
        id="dropdown-grade-question-button"
        title="Evaluează întrebarea"
        drop="down-centered"
        className="mt-3"
      >
        <Dropdown.Item
          className="text-danger"
          onClick={() => HandleSubmit("wrong")}
        >
          Greșit (<b>30 minute</b>)
        </Dropdown.Item>
        <Dropdown.Item
          className="text-success"
          onClick={() => HandleSubmit("correct")}
        >
          Corect ({DisplayInterval(intervals.correct)})
        </Dropdown.Item>
        <Dropdown.Item
          className="text-primary"
          onClick={() => HandleSubmit("easy")}
        >
          Ușor ({DisplayInterval(intervals.easy)})
        </Dropdown.Item>
      </DropdownButton>
    );
  }

  function HandleSubmit(grade) {
    questionSRV.gradeQuestion(props.questionUUID, grade).then(
      () => {
        window.location.reload();
      },
      (error) => {
        console.log(error);
      }
    );
  }

  function DisplayInterval(value) {
    const days = Math.round(value / 24);
    return (
      <b>
        {days} {days === 1 ? "zi" : "zile"}
      </b>
    );
  }

  return GradeQuestionComponent();
}
