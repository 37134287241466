import localStorageSRV from "./LocalStorageSRV";

const decode = token => {
  const requestOptions = {
    method: 'GET',
    headers: { 'Accept': 'application/json', 'Authorization': `Bearer ${token}`}
  };

  return fetch(`${process.env.REACT_APP_API_URL}/api/v1/session`, requestOptions).then(
    requestSuccess => {
      if(requestSuccess.ok) {
        return requestSuccess.json().then(parsedData => { return parsedData });
      }

      if(requestSuccess.status === 401) {
        localStorageSRV.clearStorage();
      }

      return requestSuccess.json().then(parsedData => { return Promise.reject(parsedData) });
    },
    () => {
      return Promise.reject({ error: 'Service unavailable' });
    });
}

const tokenSRV = {
  decode
}

export default tokenSRV;