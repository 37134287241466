import httpHeadersSRV from "../services/HttpHeadersSRV";
import localStorageSRV from "../services/LocalStorageSRV";

const randomQuestion = (chapters, quiz_mode = false) => {
  const requestOptions = {
    method: "GET",
    headers: httpHeadersSRV.getHeadersWithAuth(),
  };

  const generateURL = (chapters) => {
    const chaptersUUIDs = chapters
      .map((chapter) => `categories_uuids[]=${chapter}`)
      .join("&");
    return `${process.env.REACT_APP_API_URL}/api/v1/questions/random?${chaptersUUIDs}&quiz_mode=${quiz_mode}`;
  };

  return fetch(generateURL(chapters), requestOptions).then(
    (requestSuccess) => {
      if (requestSuccess.ok) {
        return requestSuccess.json().then((parsedData) => {
          return parsedData;
        });
      }
      if (requestSuccess.status === 401) {
        localStorageSRV.clearStorage();
      }
      return requestSuccess.json().then((parsedData) => {
        return Promise.reject(parsedData);
      });
    },
    () => {
      return Promise.reject({ error: "Service unavailable" });
    }
  );
};

const checkAnswer = (questionUUID, userAnswers) => {
  const requestOptions = {
    method: "POST",
    headers: httpHeadersSRV.postHeadersWithAuth(),
    body: JSON.stringify({
      question: {
        answers: userAnswers,
      },
    }),
  };

  return fetch(
    `${process.env.REACT_APP_API_URL}/api/v1/questions/${questionUUID}/answer`,
    requestOptions
  ).then(
    (requestSuccess) => {
      if (requestSuccess.ok) {
        return requestSuccess.json().then((parsedData) => {
          return parsedData;
        });
      }
      if (requestSuccess.status === 401) {
        localStorageSRV.clearStorage();
      }
      return requestSuccess.json().then((parsedData) => {
        return Promise.reject(parsedData);
      });
    },
    () => {
      return Promise.reject({ error: "Service unavailable" });
    }
  );
};

const gradeQuestion = (questionUUID, grade) => {
  const requestOptions = {
    method: "POST",
    headers: httpHeadersSRV.postHeadersWithAuth(),
    body: JSON.stringify({
      question: {
        grade: grade,
      },
    }),
  };

  return fetch(
    `${process.env.REACT_APP_API_URL}/api/v1/questions/${questionUUID}/grade`,
    requestOptions
  ).then(
    (requestSuccess) => {
      if (requestSuccess.ok) {
        return requestSuccess.json().then((parsedData) => {
          return parsedData;
        });
      }
      if (requestSuccess.status === 401) {
        localStorageSRV.clearStorage();
      }
      return requestSuccess.json().then((parsedData) => {
        return Promise.reject(parsedData);
      });
    },
    () => {
      return Promise.reject({ error: "Service unavailable" });
    }
  );
};

const questionHierarchy = (questionUUID) => {
  const url = `${process.env.REACT_APP_API_URL}/api/v1/questions/${questionUUID}/hierarchy`;
  const options = {
    method: "GET",
    headers: httpHeadersSRV.getHeadersWithAuth(),
  };
  return fetch(url, options).then(
    (res) => {
      if (res.ok) {
        return res.json().then((parsedData) => {
          return parsedData;
        });
      }
      if (res.status === 401) {
        localStorageSRV.clearStorage();
      }
      return res.json().then((parsedData) => {
        return Promise.reject(parsedData);
      });
    },
    () => {
      return Promise.reject({ error: "Service unavailable" });
    }
  );
};

const questionSRV = {
  randomQuestion,
  checkAnswer,
  gradeQuestion,
  questionHierarchy,
};

export default questionSRV;
