import React from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  useSearchParams,
  useNavigate,
  useLocation,
  Navigate,
} from "react-router-dom";
import Chapters from "./chapters/Chapters";
import Question from "./questions/Question";
import SignUp from "./signUp/signUp";
import SignIn from "./signIn/signIn";
import RequestAuth from "./services/RequestAuthSRV";
import localStorageSRV from "./services/LocalStorageSRV";
import Home from "./home/Home";
import CheckInboxPage from "./common/checkInboxPage";
import ExpiredTokenPage from "./common/expiredTokenPage";
import ForgotPassword from "./forgotPassword/forgotPassword";
import ResetPassword from "./resetPassword/resetPassword";
import Preferences from "./preferences/Preferences";
import WebsiteGuide from "./public/WebsiteGuide/WebsiteGuide";
import Statistics from "./public/Statistics/Statistics";
import SubscriptionsPage from "./subscriptions/SubscriptionsPage";
import Dashboard from "./dashboard/Dashboard";
import SubscribedPage from "./common/subscribedPage";
import RequestSubscription from "./services/RequestSubscriptionSRV";
import Account from "./account/Account";

class App extends React.Component {
  render() {
    const QuestionWrapper = (props) => {
      const navigate = useNavigate();
      const location = useLocation();
      const [searchParams] = useSearchParams();
      const chapters = searchParams.get("chapters");
      if (chapters) {
        return (
          <Question
            quizMode={props.quizMode}
            chapters={chapters}
            navigate={navigate}
            location={location}
          />
        );
      }
      return <Navigate to={props.quizMode ? "/quiz/chapters" : "/chapters"} />;
    };

    const SignInWrapper = () => {
      const navigate = useNavigate();
      const location = useLocation();
      if (localStorageSRV.getWithExp("JWT") !== null) {
        return <Navigate to="/" />;
      }
      return <SignIn navigate={navigate} location={location} />;
    };

    const PreferencesWrapper = () => {
      const navigate = useNavigate();
      const location = useLocation();

      return <Preferences navigate={navigate} location={location} />;
    };

    return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="sign-in" element={<SignInWrapper />} />
          <Route path="sign-up" element={<SignUp />} />
          <Route path="forgot_password" element={<ForgotPassword />} />
          <Route path="reset_password" element={<ResetPassword />} />
          <Route path="check_inbox" element={<CheckInboxPage />} />
          <Route path="expired_token" element={<ExpiredTokenPage />} />
          <Route path="guide" element={<WebsiteGuide />} />
          <Route path="statistics" element={<Statistics />} />
          <Route path="subscribed" element={<SubscribedPage />} />
          <Route
            path="subscriptions"
            element={
              <RequestAuth>
                <SubscriptionsPage />
              </RequestAuth>
            }
          />
          <Route
            path="account"
            element={
              <RequestAuth>
                <Account />
              </RequestAuth>
            }
          />
          <Route
            path="preferences"
            element={
              <RequestAuth>
                <PreferencesWrapper />
              </RequestAuth>
            }
          />
          <Route
            path="chapters"
            element={
              <RequestSubscription>
                <Chapters path="chapters/question" />
              </RequestSubscription>
            }
          />
          <Route
            path="chapters/question"
            element={
              <RequestSubscription>
                <QuestionWrapper quizMode={false} />
              </RequestSubscription>
            }
          />
          <Route
            path="quiz/chapters"
            element={
              <RequestSubscription>
                <Chapters path="chapters/question" />
              </RequestSubscription>
            }
          />
          <Route
            path="quiz/chapters/question"
            element={
              <RequestSubscription>
                <QuestionWrapper quizMode={true} />
              </RequestSubscription>
            }
          />
          <Route
            path="dashboard"
            element={
              <RequestSubscription>
                <Dashboard />
              </RequestSubscription>
            }
          />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </BrowserRouter>
    );
  }
}
export default App;
