import {
  Card,
  CardGroup,
  Col,
  Container,
  ListGroup,
  Row,
  Table,
} from "react-bootstrap";
import NavigationBar from "../../navigationBar/NavigationBar";
import React, { useEffect, useState } from "react";
import FooterButtons from "../FooterButtons/FooterButtons";
import localStorageSRV from "../../services/LocalStorageSRV";
import FooterBar from "../../footerBar/FooterBar";

export default function Statistics() {
  const [isDesktop, setDesktop] = useState(window.innerWidth > 650);

  const updateMedia = () => {
    setDesktop(window.innerWidth > 650);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  return (
    <div className="min-vh-100 d-flex flex-column">
      <NavigationBar />
      <Container>
        <CardGroup>
          <Row xs={1} md={1} className="my-5">
            <Col>
              <Card>
                <Card.Header as="h3" className="text-center text-primary">
                  Pentru că știm cât este de stresant să îți alegi o
                  specialitate ți-am pregătit o analiză statistică a
                  rezultatelor ultimilor ani la examenul de Rezidențiat
                </Card.Header>
              </Card>
            </Col>
            <Col>
              <Card className="text-center mt-5">
                <Card.Header as="h3" className="text-primary">
                  Cu RezInformat afli de care punctaj și poziție ai nevoie
                  analizând rezultatele examenelor de rezidențiat din ultimii 4
                  ani
                </Card.Header>
                <ListGroup variant="flush">
                  <ListGroup.Item>
                    <Card.Title>
                      Mai întâi, un scurt ghid pentru a înțelege ce reprezintă
                      fiecare variabilă și cum am ajuns la ea
                    </Card.Title>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <Card.Title className="text-primary">
                      1. Dezirabilitatea: popularitatea domeniului
                    </Card.Title>
                    <Card.Text>
                      Dezirabilitatea se calculează pe baza poziției în
                      clasament a celor ce au ales specialitatea respectivă,
                      nefiind influențată de dificultatea examenului sau de
                      punctaj
                    </Card.Text>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <Card.Title className="text-primary">
                      2. Locația în clasament și intervalul de siguranță:
                      poziția și punctajul necesare pentru o alegere cât mai
                      sigură
                    </Card.Title>
                    <Card.Text>
                      Intervalele prezentate cuprind partea de mijloc a
                      punctajelor celor ce au ales specialitatea respectivă în
                      anii trecuți, desigur, raportate la note obținute. Pentru
                      a asigura un punct de referință realist,rezultatele
                      extreme au fost omise (atât cele mici, cât și cele mari).
                    </Card.Text>
                  </ListGroup.Item>
                </ListGroup>
              </Card>
            </Col>
            <Col className="my-5">
              <Card className="text-center">
                <Card.Header as="h3" className="text-primary">
                  Ești interesat de Anatomie Patologică?
                </Card.Header>
                <Card.Body>
                  <Card.Text>
                    Aceasta are o <b>dezirabilitate de 59/100</b>, fiind{" "}
                    <b>a 11-a </b> cea mai căutată specialitate.
                  </Card.Text>
                  <Card.Text>
                    Pentru a putea alege această specializare trebuie să te
                    clasezi pe un <b>loc situat între 230-957</b>, corespunzător
                    unui <b>punctaj de 781-860 puncte</b>.
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            {isDesktop ? StatisticsTable() : null}
            {localStorageSRV.getWithExp("JWT") === null
              ? FooterButtons()
              : null}
          </Row>
        </CardGroup>
      </Container>
      <FooterBar />
    </div>
  );
}

function StatisticsTable() {
  return (
    <Col className="mt-3 mb-3">
      <Card>
        <Card.Header as="h3" className="text-center text-primary">
          Statistica repartiției pe centre universitare arată în felul următor:
        </Card.Header>
      </Card>
      <Table striped bordered>
        <thead>
          <tr>
            <th />
            <th>Dezirabilitatea</th>
            <th>Locația în clasament</th>
            <th>Intervalul de siguranță</th>
            <th>Ultima poziție</th>
            <th>Ultimul punctaj</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <b>Brașov, București și Cluj-Napoca</b>
            </td>
            <td>96/100</td>
            <td>140-450</td>
            <td>830-875</td>
            <td>595</td>
            <td>815</td>
          </tr>
          <tr>
            <td>
              <b>Sibiu, Constanța, Oradea, Craiova, Timișoara și Iași</b>
            </td>
            <td>80/100</td>
            <td>392-955</td>
            <td>781-837</td>
            <td>1725</td>
            <td>724</td>
          </tr>
        </tbody>
      </Table>
    </Col>
  );
}
