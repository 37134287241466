import React, { useEffect, useState } from "react";
import NavigationBar from "../navigationBar/NavigationBar";
import {
  Alert,
  Button,
  Card,
  Col,
  Container,
  FloatingLabel,
  Form,
  Row,
} from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import { useNavigate, useSearchParams } from "react-router-dom";
import localStorageSRV from "../services/LocalStorageSRV";
import tokenSRV from "../services/TokenSRV";
import userSRV from "../services/UserSRV";
import FooterBar from "../footerBar/FooterBar";

import "./resetPassword.css";
import logo from "../public/images/logo_full_size.png";

export default function ResetPassword() {
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token") || localStorageSRV.getWithExp("JWT");
  const navigate = useNavigate();
  const [userUuid, setUserUuid] = useState("");
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    tokenSRV.decode(token).then(
      (response) => {
        setUserUuid(response.uuid);
      },
      (error) => {
        navigate("/expired_token");
      }
    );
  }, [navigate, token]);

  const displayAlert = () => {
    if (errors.length !== 0) {
      return (
        <Row lg={2} className="mt-3 justify-content-center">
          <Col>
            {errors.map((error, index) => (
              <Alert key={index} className="text-center" variant="danger">
                <b>{error}</b>
              </Alert>
            ))}
          </Col>
        </Row>
      );
    }
  };

  const schema = Yup.object().shape({
    password: Yup.string().min(8).required(),
    password_confirmation: Yup.string()
      .oneOf(
        [Yup.ref("password"), null],
        "password confirmation must match password"
      )
      .required("password confirmation is a required field"),
  });

  return (
    <div className="min-vh-100 d-flex flex-column">
      <NavigationBar />
      {displayAlert()}
      <Container>
        <Row
          lg={2}
          className="min-vh-100 align-items-center justify-content-center"
        >
          <Col>
            <Card border="primary">
              <Card.Header
                className="bg-primary bg-gradient text-white text-center"
                as="h3"
              >
                Modifică parola
              </Card.Header>
              <Card.Img variant="top" src={logo} className="logo-img" />
              <Card.Body>
                <Card.Text>Introdu o noua parolă.</Card.Text>
                <Formik
                  initialValues={{ password: "", password_confirmation: "" }}
                  validationSchema={schema}
                  onSubmit={(values, { setSubmitting }) => {
                    userSRV.resetPassword(userUuid, values, token).then(
                      (response) => {
                        const jwt = response.jwt;
                        const expiration = response.exp;

                        tokenSRV.decode(jwt).then(
                          (response) => {
                            const userUUID = response.uuid;
                            const userEmail = response.email;
                            const userHasActiveSubscription =
                              response.active_subscription;
                            const requestPayment = response.request_payment;

                            localStorageSRV.setWithExp("JWT", jwt, expiration);
                            localStorageSRV.setWithExp(
                              "userUUID",
                              userUUID,
                              expiration
                            );
                            localStorageSRV.setWithExp(
                              "userEmail",
                              userEmail,
                              expiration
                            );
                            localStorageSRV.setWithExp(
                              "userHasActiveSubscription",
                              userHasActiveSubscription,
                              expiration
                            );
                            localStorageSRV.setWithExp(
                              "requestPayment",
                              requestPayment,
                              expiration
                            );
                            navigate("/dashboard");
                          },
                          (error) => {
                            setErrors(error.messages);
                            setSubmitting(false);
                          }
                        );
                      },
                      (error) => {
                        setErrors(error.messages);
                        setSubmitting(false);
                      }
                    );
                  }}
                >
                  {({
                    handleSubmit,
                    handleChange,
                    handleBlur,
                    values,
                    touched,
                    isValid,
                    errors,
                    isSubmitting,
                  }) => (
                    <Form noValidate onSubmit={handleSubmit}>
                      <FloatingLabel
                        controlId="user-password"
                        label="Password"
                        className="mb-3"
                      >
                        <Form.Control
                          type="password"
                          name="password"
                          placeholder="Password"
                          value={values.password}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={!!errors.password}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.password}
                        </Form.Control.Feedback>
                      </FloatingLabel>
                      <FloatingLabel
                        controlId="user-password-confirmation"
                        label="Password Confirmation"
                        className="mb-3"
                      >
                        <Form.Control
                          type="password"
                          name="password_confirmation"
                          placeholder="Password Confirmation"
                          value={values.password_confirmation}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          isInvalid={!!errors.password_confirmation}
                        />
                        <Form.Control.Feedback type="invalid">
                          {errors.password_confirmation}
                        </Form.Control.Feedback>
                      </FloatingLabel>
                      <Button
                        variant="primary"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        Modifică
                      </Button>
                    </Form>
                  )}
                </Formik>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      <FooterBar />
    </div>
  );
}
