import React from "react";
import NavigationBar from "../navigationBar/NavigationBar";
import { Card, Col, Container, Row } from "react-bootstrap";
import FooterBar from "../footerBar/FooterBar";

import "./common.css";
import logo from "../public/images/logo_full_size.png";

export default function ExpiredTokenPage() {
  return (
    <div className="min-vh-100 d-flex flex-column">
      <NavigationBar />
      <Container>
        <Row
          lg={2}
          className="min-vh-100 align-items-center justify-content-center"
        >
          <Col>
            <Card border="primary">
              <Card.Img variant="top" src={logo} className="logo-img" />
              <Card.Body>
                <Card.Text>
                  Sesiunea a expirat Sesiunea a{" "}
                  <b className="text-danger">expirat</b>
                </Card.Text>
                <Card.Text>Te rugăm încearcă din nou.</Card.Text>
              </Card.Body>
              <Card.Footer>
                <Card.Link href="/sign-in">Autentificare</Card.Link>
                <Card.Link href="/sign-up">Înregistrare</Card.Link>
                <Card.Link href="/forgot_password">Ai uitat parola?</Card.Link>
              </Card.Footer>
            </Card>
          </Col>
        </Row>
      </Container>
      <FooterBar />
    </div>
  );
}
