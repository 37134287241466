import React from "react";
import * as Yup from "yup";
import NavigationBar from "../navigationBar/NavigationBar";
import {
  Alert,
  Button,
  Card,
  Col,
  Container,
  FloatingLabel,
  Form,
  Row,
} from "react-bootstrap";
import { Formik } from "formik";
import signInSRV from "./SignInSRV";
import localStorageSRV from "../services/LocalStorageSRV";
import { Link } from "react-router-dom";
import tokenSRV from "../services/TokenSRV";
import FooterBar from "../footerBar/FooterBar";
import ScrollToTopOnMount from "../services/ScrollToTopOnMount";

import "./signIn.css";
import logo from "../public/images/logo_full_size.png";

class SignIn extends React.Component {
  constructor(props) {
    super(props);
    this.state = { errors: [] };
    this.displayAlert = this.displayAlert.bind(this);
  }

  displayAlert = () => {
    if (this.state.errors.length !== 0) {
      return (
        <Row lg={2} className="mt-5 align-items-center justify-content-center">
          <Col>
            {this.state.errors.map((error, index) => (
              <Alert key={index} className="text-center" variant="danger">
                <b>{error}</b>
              </Alert>
            ))}
          </Col>
        </Row>
      );
    }
  };

  render() {
    const schema = Yup.object().shape({
      email: Yup.string().email().required(),
      password: Yup.string().required(),
    });
    const from = this.props.location.state?.from?.pathname || "/dashboard";

    return (
      <div className="min-vh-100 d-flex flex-column">
        <ScrollToTopOnMount />
        <NavigationBar />
        <Container>
          {this.displayAlert()}
          <Row
            lg={2}
            className="min-vh-100 align-items-center justify-content-center"
          >
            <Col>
              <Card border="primary">
                <Card.Header
                  className="bg-primary bg-gradient text-white text-center"
                  as="h3"
                >
                  Autentificare
                </Card.Header>
                <Card.Img variant="top" src={logo} className="logo-img" />
                <Card.Body>
                  <Formik
                    initialValues={{ email: "", password: "" }}
                    validationSchema={schema}
                    onSubmit={(values, { setSubmitting }) => {
                      signInSRV.signIn(values).then(
                        (response) => {
                          const jwt = response.jwt;
                          const expiration = response.exp;

                          tokenSRV.decode(jwt).then(
                            (response) => {
                              const userUUID = response.uuid;
                              const userEmail = response.email;
                              const userHasActiveSubscription =
                                response.active_subscription;
                              const requestPayment = response.request_payment;

                              localStorageSRV.setWithExp(
                                "JWT",
                                jwt,
                                expiration
                              );
                              localStorageSRV.setWithExp(
                                "userUUID",
                                userUUID,
                                expiration
                              );
                              localStorageSRV.setWithExp(
                                "userEmail",
                                userEmail,
                                expiration
                              );
                              localStorageSRV.setWithExp(
                                "userHasActiveSubscription",
                                userHasActiveSubscription,
                                expiration
                              );
                              localStorageSRV.setWithExp(
                                "requestPayment",
                                requestPayment,
                                expiration
                              );
                              this.props.navigate(from, { replace: true });
                            },
                            (error) => {
                              this.setState({ errors: error.messages });
                              setSubmitting(false);
                            }
                          );
                        },
                        (error) => {
                          this.setState({ errors: error.messages });
                          setSubmitting(false);
                        }
                      );
                    }}
                  >
                    {({
                      handleSubmit,
                      handleChange,
                      handleBlur,
                      values,
                      touched,
                      isValid,
                      errors,
                      isSubmitting,
                    }) => (
                      <Form noValidate onSubmit={handleSubmit}>
                        <FloatingLabel
                          controlId="user-email"
                          label="Email"
                          className="mb-3"
                        >
                          <Form.Control
                            type="email"
                            name="email"
                            placeholder="Email"
                            value={values.email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={!!errors.email}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.email}
                          </Form.Control.Feedback>
                        </FloatingLabel>
                        <FloatingLabel
                          controlId="user-password"
                          label="Password"
                          className="mb-3"
                        >
                          <Form.Control
                            type="password"
                            name="password"
                            placeholder="Password"
                            value={values.password}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            isInvalid={!!errors.password}
                          />
                          <Form.Control.Feedback type="invalid">
                            {errors.password}
                          </Form.Control.Feedback>
                        </FloatingLabel>
                        <Button
                          variant="primary"
                          type="submit"
                          disabled={isSubmitting}
                        >
                          Log In
                        </Button>
                      </Form>
                    )}
                  </Formik>
                </Card.Body>
                <Card.Footer className="text-center">
                  <Card.Text>
                    Nu ai cont? <Link to="/sign-up">Înregistrează-te</Link>
                  </Card.Text>
                  <Link to="/forgot_password">Ai uitat parola?</Link>
                </Card.Footer>
              </Card>
            </Col>
          </Row>
        </Container>
        <FooterBar />
      </div>
    );
  }
}

export default SignIn;
