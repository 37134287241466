import httpHeadersSRV from "../services/HttpHeadersSRV";
import localStorageSRV from "../services/LocalStorageSRV";

const signIn = session => {
  const requestOptions = {
    method: 'POST',
    headers: httpHeadersSRV.postHeaders(),
    body: JSON.stringify({session: session})
  };
  
  return fetch(`${process.env.REACT_APP_API_URL}/api/v1/session`, requestOptions).then(
    requestSuccess => {
      if(requestSuccess.ok) {
        return requestSuccess.json().then(parsedData => { return parsedData });
      }
      if(requestSuccess.status === 401) {
        localStorageSRV.clearStorage();
      }
      return requestSuccess.json().then(parsedData => { return Promise.reject(parsedData) });
    },
    () => {
      return Promise.reject({ error: 'Service unavailable' });
    });
}

const signInSRV = {
  signIn
}

export default signInSRV;
