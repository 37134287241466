import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import localStorageSRV from "../services/LocalStorageSRV";
import { useLocation, useNavigate } from "react-router-dom";
import userSRV from "../services/UserSRV";
import billingSessionSRV from "../account/BillingSessionSRV";

import logo from "../public/images/logo.png";

export default function NavigationBar() {
  const navigate = useNavigate();
  const location = useLocation();

  const RightButtons = () => {
    if (localStorageSRV.getWithExp("JWT") !== null) {
      return (
        <Nav className="justify-content-end">
          <NavDropdown
            title={localStorageSRV.getWithExp("userEmail")}
            id="responsive-nav-dropdown"
            drop="down-centered"
          >
            {subscriptions()}
            <NavDropdown.Item href="/preferences">Preferințe</NavDropdown.Item>
            <NavDropdown.Item href="/reset_password">
              Modifică parola
            </NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item onClick={() => SignOut()}>
              Deconectează-te
            </NavDropdown.Item>
          </NavDropdown>
        </Nav>
      );
    } else {
      return (
        <Nav className="justify-content-end">
          <Nav.Link href="/sign-in">Autentificare</Nav.Link>
          <Nav.Link href="/sign-up">Înregistrare</Nav.Link>
        </Nav>
      );
    }
  };

  const subscriptions = () => {
    const jwt = localStorageSRV.getWithExp("JWT");
    const userHasActiveSubscription = localStorageSRV.getWithExp(
      "userHasActiveSubscription"
    );
    if (jwt !== null) {
      if (userHasActiveSubscription === true) {
        return (
          <NavDropdown.Item onClick={() => createBillingSession()}>
            Gestionează-ți abonamentul
          </NavDropdown.Item>
        );
      } else {
        return (
          <NavDropdown.Item href="/subscriptions">
            Alege un abonament
          </NavDropdown.Item>
        );
      }
    }
  };

  const LeftButtons = () => {
    return (
      <Nav className="me-auto">
        <Nav.Link href="/dashboard">Rezolvă</Nav.Link>
        <Nav.Link href="/guide">Cum te ajutăm să înveți</Nav.Link>
      </Nav>
    );
  };

  const SignOut = () => {
    userSRV.logout().then(
      (_response) => {
        localStorageSRV.clearStorage();
        if (location.pathname === "/") {
          window.location.reload();
        } else {
          navigate("/", { replace: true });
        }
      },
      (_error) => { }
    );
  };

  const createBillingSession = () => {
    billingSessionSRV.create().then(
      (response) => {
        window.location.href = response.url;
      },
      (error) => { }
    );
  };

  return (
    <Navbar
      sticky="top"
      collapseOnSelect
      expand="sm"
      bg="primary"
      variant="dark"
    >
      <Container fluid>
        <Navbar.Brand href="/">
          <img
            src={logo}
            className="rounded d-inline-block align-top"
            width="30"
            height="30"
            alt="Logo Rezidentiat"
          />{" "}
          Acasă
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          {LeftButtons()}
          {RightButtons()}
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
