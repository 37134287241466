import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import {
  Alert,
  Button,
  Card,
  Col,
  Container,
  FloatingLabel,
  Form,
  Row,
} from "react-bootstrap";
import NavigationBar from "../navigationBar/NavigationBar";
import signUpSRV from "./SignUpSRV";
import localStorageSRV from "../services/LocalStorageSRV";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import tokenSRV from "../services/TokenSRV";
import FooterBar from "../footerBar/FooterBar";

import "./signUp.css";
import logo from "../public/images/logo_full_size.png";

export default function SignUp() {
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");
  const [errors, setErrors] = useState([]);

  const displayAlert = () => {
    if (errors.length !== 0) {
      return (
        <Row lg={2} className="mt-3 justify-content-center">
          <Col>
            {errors.map((error, index) => (
              <Alert key={index} className="text-center" variant="danger">
                <b>{error}</b>
              </Alert>
            ))}
          </Col>
        </Row>
      );
    }
  };

  return (
    <div className="min-vh-100 d-flex flex-column">
      <NavigationBar />
      {displayAlert()}
      <Container>
        <Row
          lg={2}
          className="min-vh-100 align-items-center justify-content-center"
        >
          <Col>
            <Card border="primary">
              <Card.Header
                className="bg-primary bg-gradient text-center text-white"
                as="h3"
              >
                Crează-ți un cont RezInformat!
              </Card.Header>
              <Card.Img variant="top" src={logo} className="logo-img" />
              <Card.Body>
                {token !== null
                  ? SignUpWithToken(token, setErrors)
                  : SignUpWithoutToken()}
              </Card.Body>
              <Card.Footer>
                <Card.Text className="text-center">
                  Dacă ai deja cont, <Link to="/sign-in">Autentifică-te</Link>
                </Card.Text>
              </Card.Footer>
            </Card>
          </Col>
        </Row>
      </Container>
      <FooterBar />
    </div>
  );
}

function SignUpWithoutToken() {
  const navigate = useNavigate();

  const schema = Yup.object().shape({
    email: Yup.string().email().required(),
  });

  return (
    <Formik
      initialValues={{ email: "" }}
      validationSchema={schema}
      onSubmit={(values, { setSubmitting }) => {
        signUpSRV.signUp(values).then(
          (response) => {
            navigate("/check_inbox");
          },
          (error) => {
            setSubmitting(false);
          }
        );
      }}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        isSubmitting,
      }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <FloatingLabel controlId="user-email" label="Email" className="mb-3">
            <Form.Control
              type="email"
              name="email"
              placeholder="Email"
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
              isInvalid={!!errors.email}
            />
            <Form.Control.Feedback type="invalid">
              {errors.email}
            </Form.Control.Feedback>
          </FloatingLabel>
          <Button variant="primary" type="submit" disabled={isSubmitting}>
            Înregistrare
          </Button>
        </Form>
      )}
    </Formik>
  );
}

function SignUpWithToken(token, setErrors) {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");

  useEffect(() => {
    tokenSRV.decode(token).then(
      (response) => {
        setEmail(response.email);
      },
      (error) => {
        navigate("/expired_token");
      }
    );
  }, [navigate, token]);

  const schema = Yup.object().shape({
    email: Yup.string().email().required(),
    password: Yup.string().min(8).required(),
    password_confirmation: Yup.string()
      .oneOf(
        [Yup.ref("password"), null],
        "password confirmation must match password"
      )
      .required("password confirmation is a required field"),
    terms_and_conditions: Yup.bool()
      .required()
      .oneOf([true], "Terms must be accepted"),
  });

  return (
    <Formik
      enableReinitialize
      initialValues={{
        email: email,
        password: "",
        password_confirmation: "",
        terms_and_conditions: false,
      }}
      validationSchema={schema}
      onSubmit={(values, { setSubmitting }) => {
        signUpSRV.createUser(values, token).then(
          (response) => {
            const jwt = response.jwt;
            const expiration = response.exp;

            tokenSRV.decode(jwt).then(
              (response) => {
                const userUUID = response.uuid;
                const userEmail = response.email;
                const userHasActiveSubscription = response.active_subscription;
                const requestPayment = response.request_payment;

                localStorageSRV.setWithExp("JWT", jwt, expiration);
                localStorageSRV.setWithExp("userUUID", userUUID, expiration);
                localStorageSRV.setWithExp("userEmail", userEmail, expiration);
                localStorageSRV.setWithExp(
                  "userHasActiveSubscription",
                  userHasActiveSubscription,
                  expiration
                );
                localStorageSRV.setWithExp(
                  "requestPayment",
                  requestPayment,
                  expiration
                );
                navigate("/dashboard");
              },
              (error) => {
                setErrors(error.messages);
                setSubmitting(false);
              }
            );
          },
          (error) => {
            setErrors(error.messages);
            setSubmitting(false);
          }
        );
      }}
    >
      {({
        handleSubmit,
        handleChange,
        handleBlur,
        values,
        touched,
        isValid,
        errors,
        isSubmitting,
      }) => (
        <Form noValidate onSubmit={handleSubmit}>
          <FloatingLabel controlId="user-email" label="Email" className="mb-3">
            <Form.Control
              type="email"
              name="email"
              placeholder="Email"
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
              isInvalid={!!errors.email}
              disabled={email !== ""}
            />
            <Form.Control.Feedback type="invalid">
              {errors.email}
            </Form.Control.Feedback>
          </FloatingLabel>
          <FloatingLabel
            controlId="user-password"
            label="Password"
            className="mb-3"
          >
            <Form.Control
              type="password"
              name="password"
              placeholder="Password"
              value={values.password}
              onChange={handleChange}
              onBlur={handleBlur}
              isInvalid={!!errors.password}
            />
            <Form.Control.Feedback type="invalid">
              {errors.password}
            </Form.Control.Feedback>
          </FloatingLabel>
          <FloatingLabel
            controlId="user-password-confirmation"
            label="Password Confirmation"
            className="mb-3"
          >
            <Form.Control
              type="password"
              name="password_confirmation"
              placeholder="Password Confirmation"
              value={values.password_confirmation}
              onChange={handleChange}
              onBlur={handleBlur}
              isInvalid={!!errors.password_confirmation}
            />
            <Form.Control.Feedback type="invalid">
              {errors.password_confirmation}
            </Form.Control.Feedback>
          </FloatingLabel>
          <Form.Group
            className="mb-3"
            controlId="user-terms-and-conditions-checkbox"
          >
            <Form.Check
              name="terms_and_conditions"
              type="checkbox"
              label="Agree to terms and conditions"
              onChange={handleChange}
              isInvalid={!!errors.terms_and_conditions}
            />
          </Form.Group>
          <Button variant="primary" type="submit" disabled={isSubmitting}>
            Înregistrare
          </Button>
        </Form>
      )}
    </Formik>
  );
}
