import React from "react";
import localStorageSRV from "../services/LocalStorageSRV";
import NavigationBar from "../navigationBar/NavigationBar";
import { Card, Col, Container, Row } from "react-bootstrap";
import FooterBar from "../footerBar/FooterBar";

import "./common.css";
import logo from "../public/images/logo_full_size.png";

export default function SubscribedPage() {
  localStorageSRV.clearStorage();

  return (
    <div className="min-vh-100 d-flex flex-column">
      <NavigationBar />
      <Container>
        <Row
          lg={2}
          className="min-vh-100 align-items-center justify-content-center"
        >
          <Col>
            <Card border="primary">
              <Card.Img variant="top" src={logo} className="logo-img" />
              <Card.Body>
                <Card.Text>Mulțumim pentru încredere!</Card.Text>
                <Card.Text>
                  Trebuie doar să te autentifici și poți începe deja să înveți.
                </Card.Text>
              </Card.Body>
              <Card.Footer>
                <Card.Link href="/dashboard">Începe!</Card.Link>
              </Card.Footer>
            </Card>
          </Col>
        </Row>
      </Container>
      <FooterBar />
    </div>
  );
}
