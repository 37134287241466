import httpHeadersSRV from "../services/HttpHeadersSRV";
import localStorageSRV from "../services/LocalStorageSRV";

const show = userUUID => {
  const requestOptions = {
    method: 'GET',
    headers: httpHeadersSRV.getHeadersWithAuth()
  };

  return fetch(`${process.env.REACT_APP_API_URL}/api/v1/users/${userUUID}/preferences`, requestOptions).then(
    requestSuccess => {
      if(requestSuccess.ok) {
        return requestSuccess.json().then(parsedData => { return parsedData });
      }
      if(requestSuccess.status === 401) {
        localStorageSRV.clearStorage();
      }
      return requestSuccess.json().then(parsedData => { return Promise.reject(parsedData) });
    },
    () => {
      return Promise.reject({ error: 'Service unavailable' });
    });
}

const update = (preference, userUUID) => {
  const requestOptions = {
    method: 'PUT',
    headers: httpHeadersSRV.postHeadersWithAuth(),
    body: JSON.stringify({preference: preference})
  };

  return fetch(`${process.env.REACT_APP_API_URL}/api/v1/users/${userUUID}/preferences`, requestOptions).then(
    requestSuccess => {
      if(requestSuccess.ok) {
        return requestSuccess.json().then(parsedData => { return parsedData });
      }
      if(requestSuccess.status === 401) {
        localStorageSRV.clearStorage();
      }
      return requestSuccess.json().then(parsedData => { return Promise.reject(parsedData) });
    },
    () => {
      return Promise.reject({ error: 'Service unavailable' });
    });
}

const preferenceSRV = {
  show,
  update
};

export default preferenceSRV;