import React from "react";

import { Col, Container, Row } from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";

import logo from "../public/images/logo.png";
import UseWindowDimensions from "../services/useWindowDimensions";

import termsAndConditions from "../public/Termeni-si-conditii.pdf";
import privacyPolicy from "../public/Politica-de-confiden-193430-1.pdf";

export default function FooterBar() {
  const { width } = UseWindowDimensions();

  function GenerateColumnClass() {
    let className = "my-5";
    const xs = 576;

    if (width <= xs) className = "mt-5";
    return className;
  }

  return (
    <Container className="mt-auto bg-primary bg-gradient" fluid>
      <Row xs={1} md={3} className="text-white">
        <Col className={GenerateColumnClass()}>
          <img
            src={logo}
            className="rounded float-start me-2"
            width="50"
            height="50"
            alt="Logo Rezidentiat"
          />
          <h4 className="fw-bold my-3">Rezinformat</h4>
          <p className="ms-5 w-75">
            Cea mai bună soluție de pregătire pentru examenul de Rezidențiat în
            Medicină. Peste 36.000 de grile din toate detaliile cu algoritm de
            repetare spațiată.
          </p>
        </Col>
        <Col className={GenerateColumnClass()}>
          <h5 className="text-uppercase fw-bold ms-5 mb-4">Link-uri utile</h5>
          <p className="ms-5">
            <a
              href={termsAndConditions}
              className="text-reset"
              target="_blank"
              rel="noopener noreferrer"
            >
              Termeni si condiții
            </a>
          </p>
          <p className="ms-5">
            <a
              href={privacyPolicy}
              className="text-reset"
              target="_blank"
              rel="noopener noreferrer"
            >
              Politică de confidențialitate
            </a>
          </p>
          <p className="ms-5">
            <a href="https://anpc.ro/" className="text-reset">
              ANPC
            </a>
          </p>
        </Col>
        <Col className={GenerateColumnClass()}>
          <h5 className="text-uppercase fw-bold ms-5 mb-4">Contact</h5>
          <p className="ms-5">
            <Icon.HouseFill /> Str.Tudor Vladimirescu, nr.22.C, Alba Iulia,
            România
          </p>
          <p className="ms-5">
            <Icon.EnvelopeAtFill className="me-1" />
            <a className="text-reset" href="mailto:contact@rezinformat.com">
              contact@rezinformat.com
            </a>
          </p>
          <p className="ms-5">
            <Icon.TelephoneFill /> +40 748 223 456
          </p>
        </Col>
      </Row>
      <Row className="bg-secondary bg-gradient text-center text-white">
        <Col className="mt-3">
          <div className="d-inline-flex">
            <p className="me-1">&copy; {new Date().getFullYear()}</p>
            <a className="text-reset" href="/">
              Rezinformat.com
            </a>
          </div>
        </Col>
      </Row>
    </Container>
  );
}
