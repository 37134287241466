import React from "react";
import { Card } from "react-bootstrap";
import chapterSRV from "../chapters/ChapterSRV";

class Statistics extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      notGraded: 0,
      gradedWrong: 0,
      gradedCorrectAndEasyAvailable: 0,
      gradedCorrectAndEasyLooked: 0,
    };
  }

  componentDidMount() {
    chapterSRV.statistics(this.props.chapters).then(
      (response) => {
        this.setState({
          notGraded: response.not_graded,
          gradedWrong: response.graded_wrong,
          gradedCorrectAndEasyAvailable:
            response.graded_correct_and_easy_available,
          gradedCorrectAndEasyLooked: response.graded_correct_and_easy_looked,
        });
      },
      (error) => {
        console.log(error);
      }
    );
  }

  render() {
    return (
      <Card border="primary" className="text-center">
        <Card.Header as="h5" className="bg-primary bg-gradient text-white">
          Sesiunea actuală
        </Card.Header>
        <Card.Body>
          <Card.Text className="text-primary">
            Grile Nerezolvate: {this.state.notGraded}
          </Card.Text>
          <Card.Text className="text-danger">
            Grile greșite: {this.state.gradedWrong}
          </Card.Text>
          <Card.Text className="text-success">
            Grile de repetat: {this.state.gradedCorrectAndEasyAvailable}
          </Card.Text>
          <Card.Text className="text-secondary">
            Grile învățate: {this.state.gradedCorrectAndEasyLooked}
          </Card.Text>
        </Card.Body>
      </Card>
    );
  }
}

export default Statistics;
