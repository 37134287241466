import httpHeadersSRV from "../services/HttpHeadersSRV";
import localStorageSRV from "../services/LocalStorageSRV";

const createUser = (user, token) => {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', 'Accept': 'application/json', 'Authorization': `Bearer ${token}`},
    body: JSON.stringify({user: user})
  };

  return fetch(`${process.env.REACT_APP_API_URL}/api/v1/users`, requestOptions).then(
    requestSuccess => {
      if(requestSuccess.ok) {
        return requestSuccess.json().then(parsedData => { return parsedData });
      }
      if(requestSuccess.status === 401) {
        localStorageSRV.clearStorage();
      }
      return requestSuccess.json().then(parsedData => { return Promise.reject(parsedData) });
    },
    () => {
      return Promise.reject({ error: 'Service unavailable' });
    });
}

const signUp = user => {
  const requestOptions = {
    method: 'POST',
    headers: httpHeadersSRV.postHeaders(),
    body: JSON.stringify({user: user})
  };
  
  return fetch(`${process.env.REACT_APP_API_URL}/api/v1/mailers/user/sign_up`, requestOptions).then(
    requestSuccess => {
      if(requestSuccess.ok) {
        return requestSuccess.json().then(parsedData => { return parsedData });
      }
      if(requestSuccess.status === 401) {
        localStorageSRV.clearStorage();
      }
      return requestSuccess.json().then(parsedData => { return Promise.reject(parsedData) });
    },
    () => {
      return Promise.reject({ error: 'Service unavailable' });
    });
}

const signUpSRV = {
  createUser,
  signUp
}

export default signUpSRV;
