import { Card, Col, Container, Row } from "react-bootstrap";
import React from "react";
import NavigationBar from "../../navigationBar/NavigationBar";
import FooterBar from "../../footerBar/FooterBar";
import Subscription from "../../subscriptions/components/subscription";

export default function WebsiteGuide() {
  return (
    <div className="min-vh-100 d-flex flex-column">
      <NavigationBar />
      <Container>
        <Row className="my-5">
          <Col>
            <Card border="primary">
              <Card.Header
                as="h5"
                className="bg-primary bg-gradient text-white text-center"
              >
                Pentru că știm cât este de greu să găsești o metodă eficientă de
                învățat ți-am pregătit un scurt ghid
              </Card.Header>
              <Card.Body>
                <Card.Title className="text-primary">
                  1. Citește în întregime subcapitolul ales
                </Card.Title>
                <Card.Text>
                  Este suficient să citești materia fără a fi nevoie de scheme
                  sau mnemotehnici laborioase. O lectură este suficientă pentru
                  a-ți permite să înveți cu ajutorul Rezinformat
                </Card.Text>
                <Card.Title className="text-primary">
                  2. Înregistrează-te pe site și apasă butonul "Învață Acum"
                </Card.Title>
                <Card.Text>
                  Alege subcapitolul proaspăt citit și începe să rezolvi. Pentru
                  rapiditate selectează din Preferințe să parcurgi grilele în
                  ordine, iar pentru un plus de dificultate alege ordinea
                  aleatorie.
                </Card.Text>
                <Card.Title className="text-primary">
                  3. Autoevaluează-te
                </Card.Title>
                <Card.Text>
                  În funcție de corectitudinea răspunsului tău și dificultatea
                  întrebării poți să alegi timpul aproximativ după care îți va
                  fi afișată din nou grila proaspăt rezolvată. Fii cât mai
                  sincer cu tine și algoritmul se va ocupa de restul.
                </Card.Text>
                <Card.Title className="text-primary">
                  4. Repetă! Repetă! Și Repetă din Nou!
                </Card.Title>
                <Card.Text>
                  Revino zilnic și repetă! Pe măsură ce răspunzi corect, timpul
                  dintre două repetiții va crește progresiv, iar numărul zilnic
                  de grile de repetat din cadrul subcapitolului se va reduce la
                  rândul lui, pe măsură ce cunoștințele se vor sedimenta. Poți
                  selecta din Preferințe parcurgerea numai a întrebărilor deja
                  rezolvate, iar algoritmul nostru îți va afișa doar grilele pe
                  care trebuie să le reparcurgi.
                </Card.Text>
                <Card.Title className="text-primary">
                  5. Nu îți fie frică să greșești
                </Card.Title>
                <Card.Text>
                  Fie că este prima parcurgere sau că a trecut o lună de la
                  ultima repetiție, a greși e omenește! Butonul roșu resetează
                  timpul dintre repetiții și face ca întrebarea greșită să se
                  repete mai des pentru a facilita consolidarea informației.
                </Card.Text>
                <Card.Title className="text-primary">
                  6. Găsește-ți propriul stil
                </Card.Title>
                <Card.Text>
                  Poți să îți începi ziua repetând sau învățând un capitol nou.
                  Poți rezolva grile aleatoriu sau în ordinea în care
                  răspunsurile apar în carte. Grilele RezInformat îți oferă cea
                  mai mare versatilitate posibilă. Încearcă acum și vezi care
                  metodă funcționează mai bine pentru tine.
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        {Subscription()}
      </Container>
      <FooterBar />
    </div>
  );
}
