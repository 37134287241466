import NavigationBar from "../navigationBar/NavigationBar";
import { Card, Col, Container, Row } from "react-bootstrap";
import chaptersIcon from "../icons/chapters_icon.png";
import questionsIcon from "../icons/questions_icon.png";
import settingsIcon from "../icons/settings_icon.png";
import statisticsIcon from "../icons/statistics_icon.png";
import FooterBar from "../footerBar/FooterBar";
import React from "react";

export default function Dashboard() {
  return (
    <div className="min-vh-100 d-flex flex-column">
      <NavigationBar />
      <Container>
        <Row
          xs={1}
          sm={2}
          lg={3}
          className="min-vh-100 align-items-center justify-content-center"
        >
          <Col className="my-5">{ChaptersComponent()}</Col>
          <Col className="my-5">{TestYourselfComponent()}</Col>
        </Row>
      </Container>
      <FooterBar />
    </div>
  );
}

function ChaptersComponent() {
  return (
    <Card>
      <Card.Img variant="top" src={chaptersIcon} alt="Grile Rezidentiat" />
      <Card.Body>
        <Card.Title>Învață</Card.Title>
        <Card.Text>
          Învață cu ajutorul algoritmului de Repetiție Spațiată
        </Card.Text>
        <Card.Link href="/chapters" className="btn btn-primary">
          Start
        </Card.Link>
      </Card.Body>
    </Card>
  );
}

function TestYourselfComponent() {
  return (
    <Card>
      <Card.Img variant="top" src={questionsIcon} alt="Examen Rezidentiat" />
      <Card.Body>
        <Card.Title>Testează-te</Card.Title>
        <Card.Text>
          Verifică-ți cunoștințele. <br />
          <b className="text-danger">Atenție!</b> Algoritmul de Repetiție
          Spațiată va fi inactiv.
        </Card.Text>
        <Card.Link href="/quiz/chapters" className="btn btn-primary">
          Start
        </Card.Link>
      </Card.Body>
    </Card>
  );
}

function PreferencesComponent() {
  return (
    <Card>
      <Card.Img variant="top" src={settingsIcon} />
      <Card.Body>
        <Card.Title>Preferințe</Card.Title>
        <Card.Text>Personalizează-ți sesiunea de învățat</Card.Text>
        <Card.Link href="/preferences" className="btn btn-primary">
          Personalizează
        </Card.Link>
      </Card.Body>
    </Card>
  );
}

function StatisticsComponent() {
  return (
    <Card>
      <Card.Img variant="top" src={statisticsIcon} />
      <Card.Body>
        <Card.Title>Statistics</Card.Title>
        <Card.Text>
          Some quick example text to build on the card title and make up the
          bulk of the card's content.
        </Card.Text>
        <Card.Link href="/statistics" className="btn btn-primary">
          Go To Statistics
        </Card.Link>
      </Card.Body>
    </Card>
  );
}
