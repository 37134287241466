import React from "react";
import { Card, Carousel, Col, Container, Row } from "react-bootstrap";
import "./home.css";
import NavigationBar from "../navigationBar/NavigationBar";
import Subscription from "../subscriptions/components/subscription";
import FooterBar from "../footerBar/FooterBar";

export default function Home() {
  function CarouselComponent() {
    const firstImg = "/carousel_first_item.webp";
    const secondImg = "/carousel_second_item.webp";
    const thirdImg = "/carousel_third_item.webp";
    const fourthImg = "/carousel_fourth_item.webp";

    return (
      <Carousel>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={firstImg}
            alt="Grile Rezidentiat"
          />
          <Carousel.Caption className="bl">
            <div className="text-carousel-caption">
              <h4>
                Obține acces la cel mai mare număr de Grile de Medicină pentru
                pregatirea ta în Rezidențiat
              </h4>
              <p>
                Revoluționează-ți modul de învățare cu singurul algoritm de
                Repetare Spațiată disponibil pe piață
              </p>
            </div>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            src={secondImg}
            className="d-block w-100"
            loading="lazy"
            alt="Bibliografie Rezidentiat"
          />
          <Carousel.Caption>
            <div className="text-carousel-caption">
              <h3>
                Peste 36.000 de Grile din Bibliografia de Rezidențiat în
                Medicină
              </h3>
            </div>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            src={thirdImg}
            loading="lazy"
            className="d-block w-100"
            alt="Examen Rezidentiat"
          />
          <Carousel.Caption>
            <div className="text-carousel-caption">
              <h3>
                Algoritm de Repetare Spațiată cu rezultate dovedite științific
              </h3>
            </div>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            src={fourthImg}
            loading="lazy"
            className="d-block w-100"
            alt="Statistici Rezidentiat"
          />
          <Carousel.Caption>
            <div className="text-carousel-caption">
              <h3>Sesiuni personalizate de învățat</h3>
            </div>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    );
  }

  function FirstStepsComponent() {
    return (
      <Card border="primary">
        <Card.Header className="bg-primary bg-gradient text-white" as="h5">
          Te-ai hotărât ce specialitate vrei?
          <br />
          Oricare ar fi aceasta, RezInformat te ajută să îți îndeplinești visul
        </Card.Header>
        <Card.Body>
          <Card.Title className="text-primary">
            1. Înregistrează-te pe site
          </Card.Title>
          <Card.Text>
            Cu RezInformat ai acces la cel mai mare număr de grile din
            Bibliografia de Rezidențiat în Medicină. Încearcă gratuit timp de{" "}
            <b>7 zile</b> întrebări din toată tematica împreună cu algoritmul
            nostru de Repetare Spațiată.
          </Card.Text>
          <Card.Title className="text-primary">
            2. Alege-ți subcapitolul pe care dorești să îl înveți
          </Card.Title>
          <Card.Text>
            Grilele noastre au fost gândite încât să includă fiecare detaliu în
            cel puțin un răspuns corect, iar apoi împărțite în subcapitole pe
            care să le rezolvi în <b>30-60 de minute</b>. Pentru o eficiență
            maximă recomandăm ca prima rezolvare a grilelor să fie la scurt timp
            după lectura materiei respective.
          </Card.Text>
          <Card.Title className="text-primary">
            3. Învață inteligent cu algoritmul de Repetiție Spațiată
          </Card.Title>
          <Card.Text>
            După ce răspunzi la o întrebare vei avea mai multe opțiuni de a
            alege dificultatea percepută a răspunsului. Pe măsură ce parcurgi și
            reparcurgi grilele vei vedea cum zilele dintre repetiții vor crește
            progresiv în timp ce informația este consolidată. Nu îți fie frică
            să greșești. O grilă greșită se va repeta mai des până când materia
            este învățată.
          </Card.Text>
          <Card.Title className="text-primary">
            4. Repetă oriunde cu pagina Web compatibilă Desktop și Mobil
          </Card.Title>
          <Card.Text>
            Ne străduim să facem învâțatul cât mai ușor, iar pentru că repetiția
            este mama învățăturii poți repeta oriunde și oricând de pe orice
            browser Desktop sau Mobil.
          </Card.Text>
        </Card.Body>
        <Card.Footer>
          <Card.Link href="/guide">
            Accesează Ghidul Nostru de Învațat și află cum funcționează
            algoritmul de Repetiție Spațiată
          </Card.Link>
        </Card.Footer>
      </Card>
    );
  }

  function ReviewComponent() {
    return (
      <Row xs={1} md={2} className="mb-5">
        <Col className="mb-2">
          <Card border="primary">
            <Card.Body>
              <Card.Text>
                Aplicația este foarte buna on the go, pe telefon oricand poti
                repeta si nu trebuie sa stai logat non stop deoarece progresul
                iti ramane salvat. ideea de a combina materia sub forma de grile
                cu metoda anki e geniala, repetitia spatiata fiind super
                potrivita pentru a retine toate detaliile din cartile superbe de
                rezi. faptul ca exista atat de multe grile facute din toata
                materia care ne trebuie, ordonate pe subcapitole, cu accent pe
                detalii si scrise cuvant cu cuvant din carti e ce trebuie pt a
                reusi sa inveti usor. 10/10 recomand si daca as da iar examenul
                de rezidentiat as utiliza exclusiv rezinformat pt ca e facut pe
                nevoile unui examen care pune la incercare capacitatea de
                memorare a cuvintelor si nu logica din spatele acestora, reusind
                sa repeti toata materia mult mai usor decat prin a citi cartile.
              </Card.Text>
            </Card.Body>
            <Card.Footer className="text-end">
              <b>Camelia P. (absolventă promoția 2022)</b>
            </Card.Footer>
          </Card>
        </Col>
        <Col className="mb-2">
          <Card border="primary">
            <Card.Body>
              <Card.Text>
                E foarte relaxant să fac grile așa. În alte locuri, de exemplu,
                nu pot să fac extrem de multe pe zi fiindcă mă obosesc foarte
                tare. În afară de asta, grilele mi au plăcut super mult și simt
                că am rămas cu multe detalii din capitol.
              </Card.Text>
            </Card.Body>
            <Card.Footer className="text-end">
              <b>Adriana R. (absolventă promoția 2022)</b>
            </Card.Footer>
          </Card>
        </Col>
        <Col className="mb-2">
          <Card border="primary">
            <Card.Body>
              <Card.Text>
                Imi place faptul ca sunt atat de multe grile incat nici nu stiu
                daca voi avea timp sa le parcurg pe toate :)) pe alte site-uri
                am ajuns sa memorez efectiv intrebarile, iar de la un punct
                degeaba mai intram să rezolv.
              </Card.Text>
            </Card.Body>
            <Card.Footer className="text-end">
              <b>Antonia O. (absolventă promoția 2022)</b>
            </Card.Footer>
          </Card>
        </Col>
        <Col className="mb-2">
          <Card border="primary">
            <Card.Body>
              <Card.Text>
                Simt că nu mai e nevoie să recitesc materia, toate informatiile
                le-am regăsit în întrebări, lucru care mă ajută mult să îmi
                optimizez timpul de învățare...nu mai zic nimic de valori, acum
                sunt atât de ușor de memorat și de repetat.
              </Card.Text>
            </Card.Body>
            <Card.Footer className="text-end">
              <b>Mihaela B. (absolventă promoția 2022)</b>
            </Card.Footer>
          </Card>
        </Col>
        <Col className="mb-2">
          <Card border="primary">
            <Card.Body>
              <Card.Text>
                Pot să zic cu certitudine că fara rezinformat nu aș fi putut să
                îmi aleg specialitatea dorită. M-a ajutat foarte mult cu
                detaliile, cu valorile și cu lucrurile pe care nu îmi plăcea să
                le citesc. Singurul regret e că nu m-am apucat mai devreme.
              </Card.Text>
            </Card.Body>
            <Card.Footer className="text-end">
              <b>Octavian B. (absolvent promoția 2022)</b>
            </Card.Footer>
          </Card>
        </Col>
        <Col className="mb-2">
          <Card border="primary">
            <Card.Body>
              <Card.Text>
                Ce mi-a plăcut cel mai mult a fost faptul că în timpul
                examenului efectiv recunoșteam sintagmele cu răspunsul corect
                din grilele pe care le-am repetat acasă.
              </Card.Text>
            </Card.Body>
            <Card.Footer className="text-end">
              <b>Cristian A. (absolvent promoția 2022)</b>
            </Card.Footer>
          </Card>
        </Col>
      </Row>
    );
  }

  return (
    <div className="min-vh-100 d-flex flex-column">
      <NavigationBar />
      <Container>
        <Row className="mt-2">
          <Col>{CarouselComponent()}</Col>
        </Row>
        <Row className="mt-3">
          <Col>{FirstStepsComponent()}</Col>
        </Row>
        {Subscription()}
        <Row className="mt-5 mb-3">
          <Col>
            <Card border="primary">
              <Card.Header
                className="bg-primary bg-gradient text-white text-center"
                as="h5"
              >
                De la rezidenți pentru viitori rezidenți!
              </Card.Header>
              <Card.Body>
                <Card.Text>
                  Ideea de a ne crea propriile grile a venit încercând să găsim
                  cea mai eficientă metodă de învățare. După doi ani de muncă am
                  creat platforma Rezinformat. Iată ce spun o parte din
                  utilizatorii noștri!
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        {ReviewComponent()}
      </Container>
      <FooterBar />
    </div>
  );
}
