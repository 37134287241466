import httpHeadersSRV from "../services/HttpHeadersSRV";
import localStorageSRV from "../services/LocalStorageSRV";

const index = (parentUUID) => {
  const requestOptions = {
    method: "GET",
    headers: httpHeadersSRV.getHeadersWithAuth(),
  };
  let url = `${process.env.REACT_APP_API_URL}/api/v1/categories`;

  if (parentUUID !== null) {
    url = `${url}?parent_uuid=${parentUUID}`;
  }

  return fetch(url, requestOptions).then(
    (requestSuccess) => {
      if (requestSuccess.ok) {
        return requestSuccess.json().then((parsedData) => {
          return parsedData;
        });
      }
      if (requestSuccess.status === 401) {
        localStorageSRV.clearStorage();
      }
      return requestSuccess.json().then((parsedData) => {
        return Promise.reject(parsedData);
      });
    },
    () => {
      return Promise.reject({ error: "Service unavailable" });
    }
  );
};

const hierarchy = () => {
  const requestOptions = {
    method: "GET",
    headers: httpHeadersSRV.getHeadersWithAuth(),
  };

  return fetch(
    `${process.env.REACT_APP_API_URL}/api/v1/categories/hierarchy`,
    requestOptions
  ).then(
    (requestSuccess) => {
      if (requestSuccess.ok) {
        return requestSuccess.json().then((parsedData) => {
          return parsedData;
        });
      }
      if (requestSuccess.status === 401) {
        localStorageSRV.clearStorage();
      }
      return requestSuccess.json().then((parsedData) => {
        return Promise.reject(parsedData);
      });
    },
    () => {
      return Promise.reject({ error: "Service unavailable" });
    }
  );
};

const statistics = (chapters) => {
  const requestOptions = {
    method: "GET",
    headers: httpHeadersSRV.getHeadersWithAuth(),
  };

  const generateURL = (chapters) => {
    const chaptersUUIDs = chapters
      .map((chapter) => `categories_uuids[]=${chapter}`)
      .join("&");
    return `${process.env.REACT_APP_API_URL}/api/v1/categories/statistics?${chaptersUUIDs}`;
  };

  return fetch(generateURL(chapters), requestOptions).then(
    (requestSuccess) => {
      if (requestSuccess.ok) {
        return requestSuccess.json().then((parsedData) => {
          return parsedData;
        });
      }
      if (requestSuccess.status === 401) {
        localStorageSRV.clearStorage();
      }
      return requestSuccess.json().then((parsedData) => {
        return Promise.reject(parsedData);
      });
    },
    () => {
      return Promise.reject({ error: "Service unavailable" });
    }
  );
};

const chapterSRV = {
  index,
  hierarchy,
  statistics,
};

export default chapterSRV;
