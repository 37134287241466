import FolderTree from "react-folder-tree";
import "react-folder-tree/dist/style.css";
import NavigationBar from "../navigationBar/NavigationBar";
import { Button, Card, Col, Container, Row, Image } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import chapterSRV from "./ChapterSRV";
import { useLocation, useNavigate } from "react-router-dom";
import FooterBar from "../footerBar/FooterBar";

import loading from "../public/images/loading.gif";

export default function Chapters(props) {
  const path = props.path;
  let aux = [];
  const navigate = useNavigate();
  const location = useLocation();
  const [chapters, setChapters] = useState({});
  const [selectedChapters, setSelectedChapters] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    chapterSRV.hierarchy().then(
      (response) => {
        setChapters(response[0]);
        setIsLoading(false);
      },
      (error) => {
        const from = location.pathname || "/";
        console.log(error);
        navigate(from, { replace: true });
      }
    );
  }, [location, navigate]);

  const AddFolderIcon = () => null;
  const AddFileIcon = () => null;
  const EditIcon = () => null;
  const DeleteIcon = () => null;
  const CancelIcon = () => null;

  const iconComponents = {
    AddFolderIcon,
    AddFileIcon,
    EditIcon,
    DeleteIcon,
    CancelIcon,
  };

  const onTreeStateChange = (state, event) => {
    const { type } = event;
    if (type !== "checkNode") return;

    aux = [];
    saveAllSelected(state);
    setSelectedChapters(aux);
  };

  const saveAllSelected = (node) => {
    if (node.checked === 1) {
      aux = aux.concat(node.uuid);
    }
    if (node.checked === 0.5) {
      node.children.forEach((child) => saveAllSelected(child));
    }
  };

  const generateURL = (selectedChapters) =>
    `${path}?chapters=${encodeURIComponent(JSON.stringify(selectedChapters))}`;

  const loadingGif = () => {
    return (
      <div className="d-flex justify-content-center">
        <Image fluid src={loading} />
      </div>
    );
  };

  const chaptersComponent = () => {
    return (
      <Card border="primary">
        <Card.Header
          as="h3"
          className="bg-primary bg-gradient text-center text-white"
        >
          Capitole
        </Card.Header>
        <Card.Body>
          <Card.Text>
            <b>Legendă:</b> N -> Grile Nerezolvate | G -> Grile greșite | R ->
            Grile de repetat | Î -> Grile învățate
          </Card.Text>
          <FolderTree
            data={chapters}
            onChange={onTreeStateChange}
            iconComponents={iconComponents}
            initOpenStatus="custom"
          />
        </Card.Body>
        <Card.Footer>
          <Button
            href={generateURL(selectedChapters)}
            disabled={!selectedChapters.length}
          >
            Start
          </Button>
        </Card.Footer>
      </Card>
    );
  };

  return (
    <div className="min-vh-100 d-flex flex-column">
      <NavigationBar />
      <Container>
        <Row className="min-vh-100 align-items-center justify-content-center">
          <Col className="my-5">
            {isLoading ? loadingGif() : chaptersComponent()}
          </Col>
        </Row>
      </Container>
      <FooterBar />
    </div>
  );
}
