import React from "react";
import {
  Alert,
  Button,
  Card,
  Col,
  Container,
  Form,
  ListGroup,
  Row,
} from "react-bootstrap";
import questionSRV from "./QuestionSRV";
import NavigationBar from "../navigationBar/NavigationBar";
import Grade from "../grade/Grade";
import Statistics from "../statistics/Statistics";
import preferenceSRV from "../preferences/preferenceSRV";
import localStorageSRV from "../services/LocalStorageSRV";
import { Link } from "react-router-dom";
import FooterBar from "../footerBar/FooterBar";
import QuestionHierarchy from "../questionHierarchy/QuestionHierarchy";

class Question extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      question: {
        uuid: "",
        content: "",
        pageNr: "",
        hasMultipleAnswers: false,
        answers: [],
      },
      userAnswers: [],
      correctAnswers: [],
      userAnswerCorrect: false,
      userAnswerQuestion: false,
      show_multiple_choices: false,
      questionHierarchy: {},
    };
    this.answers = this.answers.bind(this);
    this.options = this.options.bind(this);
    this.displayStatistics = this.displayStatistics.bind(this);
    this.displayAlert = this.displayAlert.bind(this);
    this.displayMultipleChoices = this.displayMultipleChoices.bind(this);
    this.displayQuestion = this.displayQuestion.bind(this);
    this.borderColor = this.borderColor.bind(this);
    this.checkAnswers = this.checkAnswers.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.gradeQuestion = this.gradeQuestion.bind(this);
    this.answerQuestionButton = this.answerQuestionButton.bind(this);
    this.nextQuestion = this.nextQuestion.bind(this);
  }

  componentDidMount() {
    questionSRV
      .randomQuestion(JSON.parse(this.props.chapters), this.props.quizMode)
      .then(
        (response) => {
          if (response !== null) {
            this.setState({
              question: {
                uuid: response.uuid,
                content: response.content,
                pageNr: response.page_nr,
                hasMultipleAnswers: response.has_multiple_answers,
                answers: this.shuffleArray(response.answers),
              },
            });

            questionSRV.questionHierarchy(this.state.question.uuid).then(
              (res) => {
                this.setState({ questionHierarchy: res[0] });
              },
              (error) => console.log(error)
            );
          }
        },
        (error) => {
          console.log(error);
          const from = this.props.location.pathname || "/";
          this.props.navigate(from, { replace: true });
        }
      );

    preferenceSRV.show(localStorageSRV.getWithExp("userUUID")).then(
      (response) => {
        this.setState({
          show_multiple_choices: response.show_multiple_choices,
        });
      },
      (error) => {
        console.log(error);
      }
    );
  }

  shuffleArray = (array) => {
    let shuffleArray = array.slice();
    for (let i = shuffleArray.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffleArray[i], shuffleArray[j]] = [shuffleArray[j], shuffleArray[i]];
    }
    return shuffleArray;
  };

  isInvalid = (uuid) =>
    ((this.state.userAnswers.includes(uuid) &&
      !this.state.correctAnswers.includes(uuid)) ||
      (!this.state.userAnswers.includes(uuid) &&
        this.state.correctAnswers.includes(uuid))) &&
    this.state.userAnswerQuestion;

  handleInputChange = (event) => {
    const checkbox = event.target;

    if (checkbox.checked) {
      this.setState((prevState) => ({
        userAnswers: [...prevState.userAnswers, checkbox.value],
      }));
    } else {
      this.setState((prevState) => ({
        userAnswers: prevState.userAnswers.filter(
          (answer) => answer !== checkbox.value
        ),
      }));
    }
  };

  checkAnswers = (event) => {
    event.preventDefault();
    questionSRV
      .checkAnswer(this.state.question.uuid, this.state.userAnswers)
      .then(
        (response) => {
          this.setState({
            userAnswerQuestion: true,
            userAnswerCorrect: response.correct,
            correctAnswers: response.correct_answers,
          });
          window.scrollTo(0, 0);
        },
        (error) => {
          console.log(error);
          const from = this.props.location.pathname || "/";
          this.props.navigate(from, { replace: true });
        }
      );
  };

  gradeQuestion = () => {
    if (!this.state.userAnswerQuestion) return;
    if (this.props.quizMode) return;

    return <Grade questionUUID={this.state.question.uuid} />;
  };

  displayQuestion = () => {
    const questionComponent = (
      <Card border={this.borderColor()}>
        <Card.Header
          as="h5"
          className={`bg-${this.borderColor()} bg-gradient text-white d-flex`}
        >
          <div className="me-auto">Întrebarea: {this.state.question.uuid}</div>
          <div>pag. {this.state.question.pageNr}</div>
        </Card.Header>
        <Card.Body className="border-bottom-0">
          <Card.Title>Q: {this.state.question.content}</Card.Title>
        </Card.Body>
        <ListGroup variant="flush" className="border-top-0 border-bottom-0">
          {this.answers(this.state.question)}
        </ListGroup>
        <Card.Body className="border-top-0">
          <Form onSubmit={this.checkAnswers}>
            {this.options(this.state.question)}
            {this.answerQuestionButton()}
            {this.gradeQuestion()}
            {this.nextQuestion()}
          </Form>
        </Card.Body>
      </Card>
    );
    const noMoreQuestionAlert = (
      <Alert variant="primary" className="text-center">
        Nu mai există întrebări disponibile din capitole selectate. Alege un
        capitol nou sau verifică-ți <Link to="/preferences">preferințele</Link>
      </Alert>
    );

    if (this.state.question.uuid !== "") {
      return questionComponent;
    } else {
      return noMoreQuestionAlert;
    }
  };

  answers = (question) => {
    const alphabet = "abcdefghijklmnopqrstuvwxyz";

    return question.answers.map((answer, index) => (
      <ListGroup.Item
        key={answer.uuid}
        variant={
          this.state.correctAnswers.includes(answer.uuid) ? "success" : ""
        }
      >
        {alphabet[index].toUpperCase()}: {answer.content}
      </ListGroup.Item>
    ));
  };

  options = (question) => {
    const alphabet = "abcdefghijklmnopqrstuvwxyz";

    return question.answers.map((answer, index) => (
      <Form.Check
        key={answer.uuid}
        id={answer.uuid}
        label={alphabet[index].toUpperCase()}
        type="checkbox"
        value={answer.uuid}
        isValid={
          this.state.correctAnswers.includes(answer.uuid) &&
          this.state.userAnswers.includes(answer.uuid)
        }
        isInvalid={this.isInvalid(answer.uuid)}
        disabled={this.state.userAnswerQuestion}
        onChange={this.handleInputChange}
      />
    ));
  };

  borderColor = () => {
    if (!this.state.userAnswerQuestion) {
      return "primary";
    }

    return this.state.userAnswerCorrect ? "success" : "danger";
  };

  displayStatistics = () => {
    if (this.props.quizMode) return;
    return <Statistics chapters={JSON.parse(this.props.chapters)} />;
  };

  displayAlert = () => {
    if (!this.state.userAnswerQuestion) return;

    return (
      <Alert
        className="text-center"
        variant={this.state.userAnswerCorrect ? "success" : "danger"}
      >
        Răspuns {this.state.userAnswerCorrect ? "Corect" : "Greșit"}
      </Alert>
    );
  };

  displayMultipleChoices = () => {
    if (
      !this.state.userAnswerQuestion &&
      this.state.show_multiple_choices &&
      this.state.question.hasMultipleAnswers
    ) {
      return (
        <Alert className="text-center" variant="info">
          Întrebare cu răspuns multiplu
        </Alert>
      );
    }
  };

  answerQuestionButton = () => {
    if (this.state.userAnswerQuestion) return;

    return (
      <Button
        className="mt-3"
        variant="primary"
        type="submit"
        disabled={
          this.state.userAnswerQuestion || this.state.userAnswers.length === 0
        }
      >
        Răspunde
      </Button>
    );
  };

  nextQuestion = () => {
    if (!this.state.userAnswerQuestion) return;
    if (!this.props.quizMode) return;

    return (
      <Button
        className="mt-3"
        variant="primary"
        onClick={() => window.location.reload()}
      >
        Următoarea întrebare
      </Button>
    );
  };

  render() {
    return (
      <div className="min-vh-100 d-flex flex-column">
        <NavigationBar />
        <Container>
          <Row className="mt-5">
            <Col>
              {this.displayMultipleChoices()}
              {this.displayAlert()}
            </Col>
          </Row>
          <Row className="mb-5">
            <Col>{this.displayQuestion()}</Col>
          </Row>
          <Row xs={1} md={2} className="mb-5">
            <Col className="mb-3">
              <QuestionHierarchy
                questionHierarchy={this.state.questionHierarchy}
              />
            </Col>
            <Col>{this.displayStatistics()}</Col>
          </Row>
        </Container>
        <FooterBar />
      </div>
    );
  }
}

export default Question;
