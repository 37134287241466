import React from "react";
import NavigationBar from "../navigationBar/NavigationBar";
import { Button, Card, Col, Container, Form, Row } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import preferenceSRV from "./preferenceSRV";
import localStorageSRV from "../services/LocalStorageSRV";
import FooterBar from "../footerBar/FooterBar";

class Preferences extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      preference: {
        start_with_not_graded: false,
        start_with_graded: false,
        retrieve_random_questions: true,
        show_multiple_choices: true,
        custom_difficulty: "1",
      },
      userUUID: localStorageSRV.getWithExp("userUUID") || "",
    };
  }

  componentDidMount() {
    preferenceSRV.show(this.state.userUUID).then(
      (response) => {
        this.setState({
          preference: {
            start_with_not_graded: response.start_with_not_graded,
            start_with_graded: response.start_with_graded,
            retrieve_random_questions: response.retrieve_random_questions,
            show_multiple_choices: response.show_multiple_choices,
            custom_difficulty: response.custom_difficulty,
          },
        });
      },
      (error) => {
        console.log(error);
        const from = this.props.location.pathname || "/";
        this.props.navigate(from, { replace: true });
      }
    );
  }

  render() {
    const schema = Yup.object().shape({
      custom_difficulty: Yup.number()
        .typeError("Please enter a valid number")
        .required("Difficulty is required")
        .min(0.5, "Minimum at lest 0.5")
        .max(2, "Allowed maximum is 2"),
    });

    return (
      <div className="min-vh-100 d-flex flex-column">
        <NavigationBar />
        <Container>
          <Row className="my-5">
            <Col>
              <Card border="primary">
                <Card.Header
                  as="h3"
                  className="bg-primary bg-gradient text-white text-center"
                >
                  Preferințe
                </Card.Header>
                <Card.Body>
                  <Formik
                    enableReinitialize
                    initialValues={{
                      start_with_not_graded:
                        this.state.preference.start_with_not_graded,
                      start_with_graded:
                        this.state.preference.start_with_graded,
                      retrieve_random_questions:
                        this.state.preference.retrieve_random_questions,
                      show_multiple_choices:
                        this.state.preference.show_multiple_choices,
                      custom_difficulty:
                        this.state.preference.custom_difficulty,
                    }}
                    validationSchema={schema}
                    onSubmit={(values, { setSubmitting }) => {
                      preferenceSRV.update(values, this.state.userUUID).then(
                        (_response) => {
                          this.props.navigate("/");
                        },
                        (error) => {
                          setSubmitting(false);
                          console.log(error);
                          const from = this.props.location.pathname || "/";
                          this.props.navigate(from, { replace: true });
                        }
                      );
                    }}
                  >
                    {({
                      handleSubmit,
                      handleChange,
                      handleBlur,
                      values,
                      touched,
                      isValid,
                      errors,
                      isSubmitting,
                    }) => (
                      <Form noValidate onSubmit={handleSubmit}>
                        <Card>
                          <Card.Header as="h4" className="text-primary">
                            <div className="d-inline-flex">
                              <Form.Check
                                name="start_with_not_graded"
                                type="switch"
                                id="start_with_not_graded"
                                checked={values.start_with_not_graded}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              Rezolvă doar întrebările noi
                            </div>
                          </Card.Header>
                          <Card.Body>
                            <Card.Text>
                              Grilele afișate vor fi doar din întrebările
                              nerezolvate
                            </Card.Text>
                          </Card.Body>
                        </Card>
                        <Card className="mt-3">
                          <Card.Header as="h4" className="text-primary">
                            <div className="d-inline-flex">
                              <Form.Check
                                name="start_with_graded"
                                type="switch"
                                id="start_with_graded"
                                checked={values.start_with_graded}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              Rezolvă doar întrebările deja parcurse
                            </div>
                          </Card.Header>
                          <Card.Body>
                            <Card.Text>
                              Grilele afișate vor fi doar din întrebările de
                              repetat, a căror timp dintre două repetiții a
                              expirat
                            </Card.Text>
                          </Card.Body>
                        </Card>
                        <Card className="mt-3">
                          <Card.Header as="h4" className="text-primary">
                            <div className="d-inline-flex">
                              <Form.Check
                                name="retrieve_random_questions"
                                type="switch"
                                id="retrieve_random_questions"
                                checked={values.retrieve_random_questions}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              Afișează întrebări în ordine aleatorie
                            </div>
                          </Card.Header>
                          <Card.Body>
                            <Card.Text>
                              Grilele afișate vor alese în mod aleatoriu din
                              capitolele selectate
                            </Card.Text>
                          </Card.Body>
                        </Card>
                        <Card className="mt-3">
                          <Card.Header as="h4" className="text-primary">
                            <div className="d-inline-flex">
                              <Form.Check
                                name="show_multiple_choices"
                                type="switch"
                                id="show_multiple_choices"
                                checked={values.show_multiple_choices}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              Marchează întrebările cu complement simplu și
                              multiplu
                            </div>
                          </Card.Header>
                          <Card.Body>
                            <Card.Text>
                              Grilele afișate vor fi marcate în funcție de
                              numărul răspunsurilor corecte
                            </Card.Text>
                          </Card.Body>
                        </Card>
                        <Card className="mt-3">
                          <Card.Header as="h4" className="text-primary">
                            Dificultatea învățarii
                          </Card.Header>
                          <Card.Body>
                            <Form.Control
                              type="text"
                              name="custom_difficulty"
                              placeholder="Enter a custom difficulty level"
                              value={values.custom_difficulty}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              isInvalid={!!errors.custom_difficulty}
                            />
                            <Form.Control.Feedback type="invalid">
                              {errors.custom_difficulty}
                            </Form.Control.Feedback>
                            <Card.Title className="mt-2">
                              Reduce sau crește perioada dintre repetiții.
                            </Card.Title>
                            <Card.Text>
                              <b className="text-danger">Atenție!</b> O
                              modificare ușoară va avea efecte mici asupra
                              grilelor proaspăt învățate, cu interval mic de
                              repetare, dar va distanța mult mai mult grilele a
                              căror interval dintre două repetiții este mai mare
                            </Card.Text>
                          </Card.Body>
                        </Card>
                        <Button
                          className="mt-3"
                          variant="primary"
                          type="submit"
                          disabled={isSubmitting}
                        >
                          Salvează
                        </Button>
                      </Form>
                    )}
                  </Formik>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
        <FooterBar />
      </div>
    );
  }
}

export default Preferences;
