import {useLocation, Navigate} from "react-router-dom";
import localStorageSRV from "./LocalStorageSRV";

export default function RequestAuth({children}) {
  let location = useLocation();
  if (localStorageSRV.getWithExp("JWT") === null) {
    return <Navigate to="/sign-in" state={{ from: location }} replace />;
  }
  
  return children;
};
