import NavigationBar from "../navigationBar/NavigationBar";
import FooterBar from "../footerBar/FooterBar";

export default function Account() {
  return (
    <div className="min-vh-100 d-flex flex-column">
      <NavigationBar />
      <FooterBar />
    </div>
  );
}
