import { Navigate, useLocation } from "react-router-dom";
import localStorageSRV from "./LocalStorageSRV";

export default function RequestSubscription({ children }) {
  let location = useLocation();
  const hasActiveSubscription = localStorageSRV.getWithExp(
    "userHasActiveSubscription"
  );
  const requestPayment = localStorageSRV.getWithExp("requestPayment");
  const goToSubscriptionPage =
    hasActiveSubscription === null ||
    hasActiveSubscription === false ||
    requestPayment === true;

  if (localStorageSRV.getWithExp("JWT") === null) {
    return <Navigate to="/sign-in" state={{ from: location }} replace />;
  }

  if (goToSubscriptionPage) {
    return <Navigate to="/subscriptions" state={{ from: location }} replace />;
  }

  return children;
}
